<template>
  <div class="test">
    <div class="icon">
      <img src="./img/one.png" alt="" />
    </div>
    <div class="icon icon-two">
      <img src="./img/two.png" alt="" />
    </div>
    <div class="icon icon-three">
      <img src="./img/three.png" alt="" />
    </div>
    <div class="icon icon-four">
      <img src="./img/four.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "testHome",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.test {
  width: 100%;
min-height: 100vh;
  background-image: url("./img/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 644px 40px 98px 40px;
  box-sizing: border-box;
  .icon {
    width: 100%;
    height: 620px;
    &:not(:last-child){
      margin-bottom: 62px;
    }
    &.icon-two {
      height: 850px;
    }
    &.icon-three {
      height:728px;
    }
    &.icon-four {
      height:728px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
